import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image'
import Layout from '../../components/common/Layout.jsx';
import HealthyHeartsLayout from '../../components/healthy-hearts/HealthyHeartsLayout.jsx';

import { connect } from 'react-redux';
import { HEALTHY_HEARTS_NAVIGATION } from '../../state/actionTypes.js';

class CirculatorySystem extends React.Component {
    constructor(props){
        super(props);
        props.healthyHeartsNavigation(1);
    }

    render(){
        return (
            <Layout>
                <HealthyHeartsLayout>
                    <div className="HealthyHeartsMainContainer">
                        <div className="ContentContainer">
                            <div className="Content">
                                <div className="SectionContainer">
                                    <p className="SectionText">To understand the treatments that combat heart disease in dogs, it's important to understand how a normal, healthy heart works.</p>
                                    <p className="SectionText">The heart is a part of the circulatory system, along with the lungs and blood vessels. The pumping of the heart pushes blood on its journey through the vessels and around the body.</p>
                                </div>
                            </div>
                        </div>
                        <div className="ContentContainer Alt">
                            <div className="Content">
                                <div className="SectionContainer">
                                    <p className="SectionTitle">Lungs</p>
                                    <p className="SectionText">The lungs are the part of the body where vital oxygen is absorbed into the blood, and waste carbon dioxide is removed from the blood. As your dog inhales, the air travels in through the airways to small sacs called alveoli. There are tiny blood vessels that surround the alveoli – this is where the oxygen is absorbed, and the carbon dioxide removed.</p>
                                    <div className="ImageContainer">
                                        <Img
                                            fluid={this.props.data.circ1.childImageSharp.fluid}
                                            className="circulatoryImage"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ContentContainer">
                            <div className="Content">
                                <div className="SectionContainer">
                                    <p className="SectionTitle">Heart</p>
                                    <p className="SectionText">The heart is a muscular pump at the centre of the circulatory system. The heart and blood vessels deliver blood, rich in oxygen, to all parts of the body.</p>
                                    <p className="SectionText">The heart is divided into left and right sides and each side has a top chamber called the atrium, and a bottom chamber called the ventricle.</p>
                                    <div className="ImageContainer">
                                        <Img
                                            fluid={this.props.data.circ2.childImageSharp.fluid}
                                            className="circulatoryImage"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ContentContainer Alt">
                            <div className="Content">
                                <div className="SectionContainer">
                                    <p className="SectionText">The chambers of the heart are separated by valves. These valves are there to ensure that blood always moves forward to supply the body with oxygen. They do this by opening to allow the chambers to fill, then closing to prevent blood from flowing back the wrong way.</p>
                                    <div className="ImageContainer">
                                        <Img
                                            fluid={this.props.data.circ3.childImageSharp.fluid}
                                            className="circulatoryImage"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ContentContainer">
                            <div className="Content">
                                <div className="SectionContainer">
                                    <p className="SectionText">When blood returns to your dog’s heart from the rest of the body, it’s received into the right atrium. It’s then pumped from the atrium into the right ventricle. There are valves between the atrium and ventricle to make sure that blood flows the right way.</p>
                                    <p className="SectionText">The blood is then pumped out of the ventricle to the lungs, where it receives fresh oxygen.</p>
                                    <div className="ImageContainer">
                                        <Img
                                            fluid={this.props.data.circ4.childImageSharp.fluid}
                                            className="circulatoryImage"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ContentContainer Alt">
                            <div className="Content">
                                <div className="SectionContainer">
                                    <p className="SectionText">The newly oxygenated blood returns to the heart again, firstly into the left atrium and then the left ventricle, from where it's pumped back around the body.</p>
                                    <div className="ImageContainer">
                                        <Img
                                            fluid={this.props.data.circ5.childImageSharp.fluid}
                                            className="circulatoryImage"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ContentContainer">
                            <div className="Content">
                                <div className="SectionContainer">
                                    <p className="SectionTitle">Blood vessels</p>
                                    <p className="SectionText">Blood vessels are the pipes that blood flows through to get around the body. Blood vessels leaving the heart are called arteries. The arteries gradually divide into small capillaries in which nutrients and oxygen are released to the body cells, and carbon dioxide and other waste products are returned to the bloodstream. The blood then travels in veins back to the heart, and the whole process begins again.</p>
                                    <div className="ImageContainer">
                                        <Img
                                            fluid={this.props.data.circ6.childImageSharp.fluid}
                                            className="circulatoryImage"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </HealthyHeartsLayout>
            </Layout>
        )
    }
}

// export default CirculatorySystem;

export const query = graphql`{
    circ1: file(relativePath:{ eq:"healthyHearts/circulatory1.png" }) {
        childImageSharp {
            fluid(maxWidth: 600){
                ...GatsbyImageSharpFluid
            }
        }
    }
        circ2: file(relativePath:{ eq:"healthyHearts/circulatory2.png" }) {
        childImageSharp {
            fluid(maxWidth: 600){
                ...GatsbyImageSharpFluid
            }
        }
    }
    circ3: file(relativePath:{ eq:"healthyHearts/circulatory3.png" }) {
        childImageSharp {
            fluid(maxWidth: 600){
                ...GatsbyImageSharpFluid
            }
        }
    }
    circ4: file(relativePath:{ eq:"healthyHearts/circulatory4.png" }) {
        childImageSharp {
            fluid(maxWidth: 600){
                ...GatsbyImageSharpFluid
            }
        }
    }
    circ5: file(relativePath:{ eq:"healthyHearts/circulatory5.png" }) {
        childImageSharp {
            fluid(maxWidth: 600){
                ...GatsbyImageSharpFluid
            }
        }
    }
    circ6: file(relativePath:{ eq:"healthyHearts/circulatory6.png" }) {
        childImageSharp {
            fluid(maxWidth: 600){
                ...GatsbyImageSharpFluid
            }
        }
    }
}`

const mapDispatchToProps = dispatch => {
    return {
        healthyHeartsNavigation: healthyHeartsPage => dispatch({
            type: HEALTHY_HEARTS_NAVIGATION,
            healthyHeartsPage,
        })
    }
}

export default connect(null, mapDispatchToProps)(CirculatorySystem);
